import PropTypes from "prop-types";
import { ValidationPropTypes } from "../constants";
import { useDebouncedCallback } from "../hooks/useDebouncedCallback";
import { useStoreActions, useStoreSelectors, useStoreState } from "../store";

export function TextInput({ inputData }) {
  const { selectIsExitModal, selectInputStyle, selectCanAdvance } = useStoreSelectors();
  const { changeInput, blurInput, navigateForward } = useStoreActions();
  const { settings } = useStoreState();
  const isExitModal = selectIsExitModal();
  const inputStyle = selectInputStyle();
  const isPristine = !Object.prototype.hasOwnProperty.call(inputData.validation, "isValid");
  const isValid = inputData.validation.isValid;
  const isTouched = inputData.isTouched;
  let classes = `text-input w-full rounded border p-3 shadow shadow-gray-100 mt-2 appearance-none outline-none text-neutral-800 col-auto xxs:col-span-2 ${
    (!isPristine && isTouched && !isValid) ? "border-red-500" : "border-gray-300"
  }`;

  if (isExitModal || inputStyle === "square") {
    classes = `w-full p-5 placeholder-grey-dark text-base uppercase font-bold mb-2 tracking-wider text-center animate-slideLeft ${
      (!isPristine && isTouched && !isValid)
        ? "outline-pink-error outline-2 outline-offset-4 outline-dashed"
        : "outline-gray-300 outline-2 outline-offset-1 focus:outline-dashed"
    }`;
  }
 

  const errorClasses = settings.inputErrorClass ?? (isExitModal ? "text-input__validation-error text-white" : "text-red-500");

  // Updates the input value when typing.
  const handleInputChange = useDebouncedCallback((event) => {
    changeInput({ name: inputData.name, value: event.target.value });
  }, 250);

  // Trigger validation when the user leaves the input.
  const handleInputBlur = () => {
    blurInput({ name: inputData.name });
  };

  const handleInputKeyDown = useDebouncedCallback((event) => {
    if (event.key === "Enter" && selectCanAdvance()) {
      navigateForward();
    }
  }, 250);

 
  return (
    <>
      {inputData.label && <div className="font-bold mb-4 mt-4 text-xl">{inputData.label}</div>}
      <input
        type={inputData.type}
        autoComplete={inputData.type === "email" ? "email" : null}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        name={inputData.name}
        defaultValue={inputData.value}
        placeholder={inputData.placeholder}
        aria-label={inputData.placeholder}
        className={`${classes}`}
        tabIndex={0}
      ></input>
      {isTouched && !isValid && (
        <div className={`mt-2 text-sm ${errorClasses}`}>
          {inputData.validation.error}
        </div>
      )}
    </>
  );
}

TextInput.propTypes = {
  inputData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validation: ValidationPropTypes,
  }),
};
