import FormStep from "../FormStep";
import LegalConsent from "../LegalConsent";
import { NavigationButton } from "../NavigationButton";
import StepIndicator from "../StepIndicator";
import { useStoreActions, useStoreSelectors, useStoreState } from "../../store";
import { useEffect, useRef } from "react";

export default function ExitModalForm() {
  const { settings } = useStoreState();
  const { selectCurrentStep, selectCanAdvance, selectStepNumber, selectNextButtonText } = useStoreSelectors();
  const { navigateForward } = useStoreActions();
  const currentStep = selectCurrentStep();
  const canAdvance = selectCanAdvance();
  const stepNumber = selectStepNumber();

  const { exitmodal, wrapperClasses } = settings;
  const { content } = exitmodal;
  const nextButtonText = selectNextButtonText();

  const showLegalConsent = currentStep.lastStep && settings.showLegalConsentText;
  const nextButtonBackgroundClass = settings.nextButtonBackgroundClass ?? "bg-grey-dark";

  return (
    <div className={`${wrapperClasses ?? "bg-pink-dark"} p-4`}>
      <StepIndicator total={settings.stepper} current={stepNumber} />
      <div className="text-white text-center space-y-2 mx-auto my-4 sm:pt-0">
        <div className="bg-white p-4 mx-auto w-28 h-28 rounded-full m-4 hidden sm:block">
          <img className="modal-img w-full h-full object-scale-down" src={exitmodal.serviceIcon} alt="icon of grid with indicating coolsculpting " />
        </div>
        <div className="modal-content-promo px-2 tracking-wider font-bold">
          <p className="text-base">{content.firstLine.text}</p>
          <p className={`${content.secondLine.classes ?? "text-7xl"} py-2 my-2 uppercase`}>{content.secondLine.text}</p>
          <p className={`${content.thirdLine.classes ?? "text-base"}`}>
            {content.thirdLine.lineOne}
            {content.thirdLine.lineTwo && (
              <>
                <br />
                {content.thirdLine.lineTwo}
              </>
            )}
            <sup className="-top-2">
              {content.supIcon && <img draggable="false" role="img" className="inline mx-1 h-3 w-3" alt="supscript icon" src={content.supIcon} />}
              {content.supText}
            </sup>
          </p>
        </div>
        <div className="text-white text-xs text-capitalize">
          Subject To
          <a href={exitmodal.legalLink} target="_blank" rel="noreferrer" className="ml-1 underline text-white">
            Offer Terms
          </a>
        </div>
      </div>
      <FormStep />
      <div className="w-full flex flex-col mt-4 justify-between">
        {showLegalConsent && <LegalConsent />}
        <NavigationButton
          text={nextButtonText}
          buttonMode={"square"}
          backgroundClasses={nextButtonBackgroundClass}
          onNavButtonClicked={() => navigateForward()}
          disabled={!canAdvance}
        />
      </div>
    </div>
  );
}
