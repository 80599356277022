import Cookies from "js-cookie";
import CommonSteps from "../CommonSteps.json";

export const pushToDataLayer = (event, data, toString) => {
  window.dataLayer = window.dataLayer || [];

  if (toString === undefined) {
    toString = false;
  }

  if (toString) {
    data = JSON.stringify(data);
  }

  window.dataLayer.push({ event, data });
};

export const setIntellimizeClientID = () => {
  try {
    const clientID = document.getElementById("clientID");
    if (clientID && clientID.value.trim().length > 0) {
      const clientIDValue = clientID.value.trim();
      if (window.intellimize && typeof window.intellimize.ready === "function") {
        window.intellimize.ready(function () {
          window.intellimize.setUserId("laseraway", clientIDValue);
        });
      } else {
        console.error("Intellimize not loaded or ready function not available.");
      }
    } else {
      console.error("clientID is null, empty, or contains only whitespace.");
    }
  } catch (e) {
    console.error("An error occurred while setting the Intellimize client ID.", e);
  }
};

export const sendGoogleAnalyticsEvent = (eventName, eventData) => {
  try {
    const gtag = window.gtag;
    if (typeof gtag === "function") {
      gtag("event", eventName, { payload: JSON.stringify(eventData) });
    } else {
      throw new Error("Google Analytics not loaded. Form Submit event not sent.");
    }
  } catch (e) {
    console.warn(e);
  }
};

export const validateField = (value, validationRules) => {
  if (validationRules.required != null) {
    const isValid = value != null && value.length > 0;
    if (!isValid) {
      return { isValid, error: validationRules.required };
    }
  }
  if (validationRules.minLength != null) {
    const isValid = value != null && value.length >= validationRules.minLength.value;
    if (!isValid) {
      return { isValid, error: validationRules.minLength.message };
    }
  }
  if (validationRules.regex != null) {
    const isValid = value.match(validationRules.regex.value) != null;
    if (!isValid) {
      return { isValid, error: validationRules.regex.message };
    }
  }
  return { isValid: true };
};

// this function is responsible to do any extra step after the initial load, usually select option from remote or dom
export const processAppData = async (form) => {
  const stepPromises = form.steps.map(async (step) => {
    if (step.commonStep === true) {
      step = { ...CommonSteps[step.id], ...step };
    }
    if (step.type === "input" && step.inputs.some((input) => input.type === "select" && ["remote", "dom"].includes(input.mode))) {
      const inputsPromises = step.inputs.map(async (input) => {
        if (input.type === "select" && input.mode === "remote") {
          try {
            const result = await fetch(input.remoteUrl);
            const options = await result.json();
            return { ...input, options };
          } catch (error) {
            return Promise.resolve(input);
          }
        }
        if (input.type === "select" && input.mode === "dom") {
          try {
            const selectSource = document.querySelector(input.querySelector);
            const options = Array.from(selectSource.children).map((child) => {
              const nodeName = child.nodeName;
              if (nodeName === "OPTGROUP") {
                const options = Array.from(child.children).map((subchild) => ({
                  text: subchild.text,
                  value: subchild.value,
                  classList: subchild.classList.toString(),
                }));
                return { text: child.label, type: "group", options };
              }
              return { text: child.text, value: child.value, classList: child.classList.toString() };
            });
            return { ...input, options };
          } catch (error) {
            return Promise.resolve(input);
          }
        }
        return Promise.resolve(input);
      });
      const inputs = await Promise.all(inputsPromises);
      return { ...step, inputs };
    }
    return Promise.resolve(step);
  });
  const steps = await Promise.all(stepPromises);
  return { ...form, steps };
};

const extractGoogleAnalyticsUtm = () => {
  const utmz = Cookies.get("__utmz");
  const utma = Cookies.get("__utma");

  let result = {};
  if (utmz != null) {
    const visitorSegs = utmz.split(".").slice(4).join(".").split("|");
    result = Object.fromEntries(visitorSegs.map((segment) => segment.split("=")));
  }

  if (utma != null) {
    const visitor = utma.split(".");
    const clientID = `${visitor[1]}.${visitor[2]}`;
    result.clientID = clientID;
  }

  return result;
};

export const loadUtmParams = () => {
  const utmParams = extractGoogleAnalyticsUtm();
  const queryParams = new URLSearchParams(location.search);
  const utmMapping = [
    { selector: ".utm-source", value: queryParams.get("utm_source") ?? utmParams.utmcsr },
    { selector: ".utm-medium", value: queryParams.get("utm_medium") ?? utmParams.utmcmd },
    { selector: ".client-id", value: utmParams.clientID },
    { selector: ".utm-campaign", value: queryParams.get("utm_campaign") ?? utmParams.utmccn },
    { selector: ".utm-content", value: queryParams.get("utm_content") ?? utmParams.utmcct },
  ];
  utmMapping
    .filter((entry) => entry.value != null)
    .forEach((entry) => {
      document.querySelectorAll(entry.selector).forEach((element) => (element.value = entry.value));
    });
};
