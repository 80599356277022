import PropTypes from "prop-types";

export function Badge({ text, classes }) {
  return <div className={`${classes}`}>{text}</div>;
}

export function TextEntry({ text, value }) {
  return Array.isArray(text) ? (
    text.map((textEntry, index) => (
      <div className={`${textEntry.classes}`} key={`${value}-${index}`}>
        {textEntry.text}
        {textEntry.supText && <sup className="-top-2">{textEntry.supText}</sup>}
      </div>
    ))
  ) : (
    <div className="inline">{text}</div>
  );
}

export function SupText({ text }) {
  return <sup className="-top-2">{text}</sup>;
}

SupText.propTypes = {
  text: PropTypes.string,
};

TextEntry.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        classes: PropTypes.string,
        supText: PropTypes.string,
      })
    ),
  ]),
  value: PropTypes.string.isRequired,
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.string,
};
