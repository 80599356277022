import { AppStatus, FormTypes } from "../constants";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import Cookies from "js-cookie";
import { validateField, processAppData, loadUtmParams, pushToDataLayer, sendGoogleAnalyticsEvent, setIntellimizeClientID } from "./services";
const devMode = import.meta.env.MODE === "development";

const formTarget = "/wp-json/leadsHandler/v1/submit/leads";
const formPostUrl = devMode ? `https://localhost:9696${formTarget}` : window.location.origin.replace('get', 'www') + formTarget;

const initialState = {
  currentStepId: null,
  settings: {
    formType: "standard",
    backButton: true,
    autoAdvance: false,
    formEmphasis: false,
    useServiceHiddenField: true,
    useLocationQueryParam: false,
    showAccolades: true,
    fullWidthFormStep: true,
    showLegalConsentText: true,
    showStepsIndicator: true,
    legalTextSelector: "#rlf-legal",
    theme: null,
  },
  steps: [],
  flow: [],
  status: AppStatus.loading,
  error: null,
};

const getCurrentStep = (state) => state.steps.find((step) => step.id === state.currentStepId);

const getFormattedInputValue = (input) => {
  if (input.type === "tel") {
    return input.value
      .replace(/^\+1/, "") // remove country calling code (leading +1)
      .replace(/[^0-9]/g, ""); // remove all non-digits
  }
  return input.value;
};

const getFormJourneyFromState = (flow, steps) =>
  flow.map((step, index) => {
    const historyStep = steps.find((stateStep) => stateStep.id === step);
    let answer = null;
    if (historyStep.type === "button-selection") {
      answer = historyStep.answers.filter((answer) => answer.selected).map((answer) => answer.value);
    }
    if (historyStep.type === "input") {
      answer = historyStep.inputs.map((input) => ({ name: input.name, value: getFormattedInputValue(input) }));
    }
    return { step: index + 1, stepId: historyStep.id, question: historyStep.question, answer, type: historyStep.type };
  });

const getLastStepFromJourney = (journey) => {
  const lastStep = journey[journey.length - 1];
  return { step: lastStep.step, stepId: lastStep.stepId };
};

const formVariationChanges = (settings, journey, postData) => {
  const { formType } = settings;
  if (formType === FormTypes.BundleSelector) {
    const servicesStep = journey.find((entry) => entry.stepId === "hair-removal-services");
    const selectionCount = servicesStep.answer.length;
    const discountStep = settings.indicator.steps.findLast((entry) => selectionCount >= entry.selections);
    const discount = discountStep?.texts[1].text ?? "";
    postData["internal_notes"] = `${discount} - BUNDLE & SAVE LEAD: ${servicesStep.answer.join(",")}`;
  }
  if (settings.cro != null && settings.cro === "laweb-7615") {
    postData["name"] = `${postData["first-name"]} ${postData["last-name"]}`;
  }
  return postData;
};

const prepareFormPostData = (journey, settings) => {
  const formFields = journey
    .filter((entry) => entry.type === "input")
    .reduce((inputs, entry) => {
      const fields = entry.answer.reduce((obj, answer) => ({ ...obj, [answer.name]: answer.value }), {});
      return { ...inputs, ...fields };
    }, {});
  let postData = { journey, ...formFields };
  const hiddenFieldsDiv = document.getElementById("react-hidden-fields");
  if (hiddenFieldsDiv) {
    const hiddenFieldsElements = hiddenFieldsDiv.querySelectorAll('input[type="hidden"]');
    const hiddenFields = Array.from(hiddenFieldsElements).reduce((obj, element) => ({ ...obj, [element.name]: element.value }), {});
    postData = { ...postData, ...hiddenFields };
  }
  if (settings.useServiceHiddenField == false) {
    const journeyService = journey.find((entry) => entry.stepId === "services");
    postData.service = journeyService?.answer?.[0];
  }
  if (settings.service) {
    postData["service"] = settings.service;
  }
  if (settings.internalNotes) {
    postData["internal_notes"] = settings.internalNotes;
  }
  if (settings.utm_source) {
    postData["utm_source"] = settings.utm_source;
  }

  if (settings.fieldOverride) {
    postData = { ...postData, ...settings.fieldOverride };
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (settings.useLocationQueryParam) {
    const queryLocation = urlParams.get("location");
    postData["your-city"] = queryLocation ?? "Virtual Consultations";
  }

  if (settings.fieldsToPayload) {
    const fieldsToAdd = settings.fieldsToPayload.reduce((result, field) => {
      const journeyField = journey.find((entry) => entry.stepId === field.sourceId);
      const fieldAnswer = Array.isArray(journeyField.answer) ? journeyField.answer.join(",") : journeyField.answer;
      return { ...result, [field.targetName]: fieldAnswer };
    }, {});
    postData = { ...postData, ...fieldsToAdd };
  }
  postData = formVariationChanges(settings, journey, postData);
  return postData;
};

const bindSelectors = (store) => {
  const selectCurrentStep = () => getCurrentStep(store.getState());
  const selectHasPreviousStep = () => {
    const currentStep = selectCurrentStep();
    return currentStep && !currentStep.firstStep;
  };
  const selectCanAdvance = () => {
    const currentStep = selectCurrentStep();
    let canAdvance = false;
    if (currentStep && currentStep.type === "button-selection") {
      const currentStepSelections = currentStep.answers.filter((answer) => answer.selected);
      const requiredSelections = currentStep.requiredSelections ?? 1;
      canAdvance = currentStepSelections.length >= requiredSelections;
    }
    if (currentStep && currentStep.type === "input") {
      canAdvance = currentStep.inputs.every((input) => input.value != null && input.validation.isValid);
    }
    return canAdvance;
  };
  const selectNextButtonText = () => {
    const currentStep = selectCurrentStep();
    let nextButtonText = "Next";
    if (currentStep.nextButtonText) {
      nextButtonText = currentStep.nextButtonText;
    }
    if (currentStep.lastStep) {
      nextButtonText = store.getState().settings.submitButtonText ?? "Submit";
    }
    return nextButtonText;
  };
  const selectLegalTextColor = () => {
    const defaultColor = [FormTypes.ExitModal, FormTypes.DarkMode].includes(store.getState().settings.formType) ? "text-white" : "text-black";
    return store.getState().settings.legalTextColor ?? defaultColor;
  };
  const selectLegalTextSelector = () => store.getState().settings.legalTextSelector;
  const selectButtonSelectionType = () => store.getState().settings.buttonSelectionType;
  const selectIsExitModal = () => store.getState().settings.formType === "exitmodal";
  const selectInputStyle = () => store.getState().settings.inputStyle;
  const selectStepNumber = () => store.getState().flow.length;

  return {
    selectCurrentStep,
    selectCanAdvance,
    selectHasPreviousStep,
    selectNextButtonText,
    selectLegalTextColor,
    selectLegalTextSelector,
    selectButtonSelectionType,
    selectIsExitModal,
    selectInputStyle,
    selectStepNumber,
  };
};

const bindActions = (store) => {
  const displayAppError = (error) =>
    store.setState(() => {
      console.error(error);
      return { status: AppStatus.error, error: "Well, this is a hairy situation... Please try again later" };
    });
  // Updates the input value with onChange event
  const changeInput = (payload) =>
    store.setState((state) => {
      const { name, value } = payload;
      const currentStep = getCurrentStep(state);
      const input = currentStep.inputs.find((input) => input.name === name);
      // Update the input value in the current step.
      input.value = value;
      const result = validateField(input.value, input.validation.rules);
      input.validation.isValid = result.isValid;

      input.isTouched = false;
      
      // Return the updated state to trigger a re-render.
      return { steps: [...state.steps] };
    });
  //Runs validation on Blur
  const blurInput = (payload) =>
    store.setState((state) => {
      const { name } = payload;
      const currentStep = getCurrentStep(state);
      const input = currentStep.inputs.find((input) => input.name === name);

      input.isTouched = true;

      const result = validateField(input.value, input.validation.rules);
      input.validation.isValid = result.isValid;

      // Store any validation errors if the input is invalid.
      if (!input.validation.isValid) {
        input.validation.error = result.error;
      }

      // Return the updated state to reflect validation changes.
      return { steps: [...state.steps] };
    });
  const changeAnswer = (payload) =>
    store.setState((state) => {
      const currentStep = getCurrentStep(state);
      if (currentStep.canSelectMultiple) {
        const answer = currentStep.answers.find((answer) => answer.value === payload);
        answer.selected = answer.selected != null ? !answer.selected : true;
      } else {
        currentStep.answers.forEach((answer) => (answer.selected = answer.value === payload));
        if (state.settings.autoAdvance) {
          // using a small timeout to apply the auto advance just so there's enough time for seeing the user's choice being highlighted
          setTimeout(navigateForward, 200);
        }
      }
      return { steps: [...state.steps] };
    });
  const loadData = async (jsonData) => {
    try {
      const processedData = await processAppData(jsonData);
      return store.setState((state) => {
        const { settings, steps } = processedData;
        const firstStep = steps.find((step) => step.firstStep);
        return {
          settings: {
            ...state.settings,
            ...settings,
          },
          steps,
          currentStepId: firstStep.id,
          flow: [firstStep.id],
          status: AppStatus.ready,
          error: null,
        };
      }, true);
    } catch (error) {
      return displayAppError(error);
    }
  };

  const handleFormSubmission = async (state) => {
    try {
      if (window.grecaptcha == null) {
        displayAppError("Recaptcha lib not present");
        return;
      }
      const siteKeyElement = document.getElementById("recaptcha_site_key");
      const recaptchaSiteKey = siteKeyElement?.value;
      if (recaptchaSiteKey == null) {
        displayAppError("Recaptcha site key not found");
        return;
      }
      const recaptchaTokenElement = document.getElementById("g-recaptcha-response");
      if (recaptchaTokenElement == null) {
        displayAppError("Recaptcha token element not found");
        return;
      }

      const recaptchaToken = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action: "FORM_LA" });
      recaptchaTokenElement.value = recaptchaToken;

      loadUtmParams();
      const formJourney = getFormJourneyFromState(state.flow, state.steps);
      const postData = prepareFormPostData(formJourney, state.settings);
      const lastUserStep = getLastStepFromJourney(formJourney);
      pushToDataLayer("form-journey", lastUserStep, true);
      pushToDataLayer("form_complete", { enhanced_conversion_data: { email: postData.email } });
      const reponse = await fetch(formPostUrl, {
        method: "POST",
        cache: "no-cache",
        body: JSON.stringify(postData),
      });
      const leadResponse = await reponse.json();

      const urlParts = window.location.host.split(".");
      urlParts.splice(0, 1);
      const domain = urlParts.join(".");

      setIntellimizeClientID();

      const oneHourFromNow = new Date().getTime() + 3600 * 1000;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const la_lead_uid = leadResponse.data.leads_app_data.lead_uid;
      Cookies.set("la_lead_uid", la_lead_uid, { expires: oneHourFromNow });
      if (window.gtag) {
        window.gtag("config", "G-EXF99D5TPC", {
          user_id: la_lead_uid,
        });
      }

      if (leadResponse.success === true) {
        const successCookieData = leadResponse.messages.success_cookie_data;
        Cookies.set("form_data", successCookieData, { expires: oneHourFromNow, domain });
        if (urlParams.get("noRedirect")) {
          displayAppError("noRedirect");
          return;
        }
        if (state.settings.quiklyTracking === true && typeof qData === "function") {
          qData("loyalty_signup", { type: "Lead", requestType: "beaconAPI" });
        }
        sendGoogleAnalyticsEvent("Form Submit", "Confirmed Lead");

        // This is need so that on Get, if the Impact UUID is present as a cookie value, the value gets passed to the WWW thank you page as a query string param
        const subdomain = window.location.hostname.split(".")[0].toLowerCase();
        let impactQueryStringParam = "";

        if (subdomain === "get") {
          const impactUUIDCookieName = "impact_uuid";

          let impactUUID = Cookies.get(impactUUIDCookieName);
          if (impactUUID) {
            impactQueryStringParam = "?impact_id=" + impactUUID;
          }
        }

        let redirectTarget = "/thank-you/" + impactQueryStringParam;
        document.location.href = redirectTarget;
      } else {
        throw new Error(leadResponse.errors.join(" | "));
      }
    } catch (error) {
      displayAppError(Object.prototype.hasOwnProperty.call(error, "toString") ? error.toString() : JSON.stringify(error));
    }
  };

  const navigateForward = async () => {
    const state = store.getState();
    const currentStep = getCurrentStep(state);
    if (currentStep.type === "input") {
      const validatedInputs = currentStep.inputs.map((input) => {
        const result = validateField(input.value, input.validation.rules);
        return { ...input, validation: { ...input.validation, ...result } };
      });
      currentStep.inputs = validatedInputs;
      const stepValid = validatedInputs.every((input) => input.validation.isValid);
      if (!stepValid) {
        store.setState({ steps: [...state.steps] });
        return;
      }
    }
    const formJourney = getFormJourneyFromState(state.flow, state.steps);
    const lastUserStep = getLastStepFromJourney(formJourney);
    pushToDataLayer("form-journey", lastUserStep, true);

    if (currentStep.lastStep) {
      store.setState({ status: AppStatus.loading });
      await handleFormSubmission(state);
      return;
    }

    let target = currentStep.target;
    const newState = { ...state };
    if (currentStep.dynamicTarget) {
      const selectedAnswers = currentStep.answers.filter((answer) => answer.selected);
      if (currentStep.canSelectMultiple && selectedAnswers.length > 1) {
        newState.flowQueue = [];
        newState.executedFlows = [];
        target = selectedAnswers[0].target;
        newState.executedFlows.push(target);
        newState.flowQueue = selectedAnswers.slice(1).map((answer) => answer.target);
        newState.multipleDynamicEndStep = currentStep.multipleDynamicEndStep;
      } else {
        // for now we always require ate least one selection for each step
        target = selectedAnswers[0].target ?? target;
      }
    } else {
      // we already have identified that we need to possibily execute multiple flows
      if (newState.flowQueue != null) {
        if (newState.flowQueue.length > 0 && newState.multipleDynamicEndStep == target) {
          target = newState.flowQueue[0];
          newState.executedFlows.push(target);
          newState.flowQueue = newState.flowQueue.slice(1);
          if (currentStep.id === target) {
            target = newState.flowQueue[0] ?? currentStep.target;
          }
        } else {
          target = newState.multipleDynamicEndStep;
          newState.flowQueue = null;
        }
      }
    }

    const nextStep = newState.steps.find((step) => step.id === target);
    newState.currentStepId = nextStep.id;
    newState.flow.push(nextStep.id);
    store.setState({ ...newState }, true);
  };

  const navigateBack = () =>
    store.setState((state) => {
      const newState = { ...state };
      const currentStep = getCurrentStep(newState);
      const newFlow = newState.flow.filter((stepId) => stepId != currentStep.id);
      const previousStepId = newFlow[newFlow.length - 1];
      const previousStep = newState.steps.find((step) => step.id === previousStepId);
      if (newState.executedFlows != null && newState.executedFlows.includes(newState.currentStepId)) {
        newState.executedFlows = newState.executedFlows.filter((stepId) => stepId != newState.currentStepId);
        if (newState.flowQueue == null) {
          newState.flowQueue = [];
        }
        newState.flowQueue = [newState.currentStepId, ...newState.flowQueue];
      }
      newState.currentStepId = previousStep.id;
      newState.flow = newFlow;
      return { ...newState };
    }, true);

  return { displayAppError, changeInput, blurInput, changeAnswer, loadData, navigateForward, navigateBack };
};

const createFormStore = () => {
  const formStore = create(
    devtools(() => ({
      ...initialState,
    }))
  );
  const selectors = bindSelectors(formStore);
  const actions = bindActions(formStore);
  return { state: formStore, selectors, actions };
};

export default createFormStore;
