export default function Accolades() {
  return (
    <div className="accolades w-full max-w-sm flex flex-col items-start mx-auto md:mr-auto md:ml-8 mt-8 text-left">
      <div className="accolade-group-1 flex items-center gap-2 mb-4 accolades__item">
        <img
          className="accolade-check-1 w-3 h-3 accolades__icon"
          src="https://res.cloudinary.com/laseraway-com/image/upload/v1656429007/cro/circle-check-regular_1_1.svg"
          alt="5 Million Treatments Performed"
        />
        <div className="accolade-1 text-sm text-grey-dark accolades__text">5 Million Treatments Performed</div>
      </div>
      <div className="accolade-group-2 flex items-center gap-2 mb-4 accolades__item">
        <img
          className="accolade-check-2 w-3 h-3 accolades__icon"
          src="https://res.cloudinary.com/laseraway-com/image/upload/v1656429007/cro/circle-check-regular_1_1.svg"
          alt="The Only Aesthetic Leader Open 7 Days A Week"
        />
        <div className="accolade-2 text-sm text-grey-dark accolades__text">The Only Aesthetic Leader Open 7 Days A Week</div>
      </div>
      <div className="accolade-group-3 flex items-center gap-2 mb-4 accolades__item">
        <img
          className="accolade-check-3 w-3 h-3 accolades__icon"
          src="https://res.cloudinary.com/laseraway-com/image/upload/v1656429007/cro/circle-check-regular_1_1.svg"
          alt="25+ Board Certified Dermatologists"
        />
        <div className="accolade-3 text-sm text-grey-dark accolades__text">25+ Board Certified Dermatologists</div>
      </div>
    </div>
  );
}
