import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { StoreProvider } from "./FormProvider.jsx";

export const renderInstance = (node) => {
  const dataContainer = node?.parentNode?.querySelector(":scope > #rlf-data-container");
  if (dataContainer == null) {
    console.warn("Data container not found");
    return;
  }

  // we're using a custom property to store the root and avoid calling createRoot multiple times
  if (!node.__reactRoot) {
    node.__reactRoot = ReactDOM.createRoot(node);
  }

  node.__reactRoot.render(
    <StoreProvider>
      <App dataContainer={dataContainer} />
    </StoreProvider>
  );
};
