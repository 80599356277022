import { RadioButtonImagePropTypes } from "../constants";
import { useStoreState } from "../store";

export function RadioButtonImage({ title, isSelected, className }) {
  const { settings } = useStoreState();
  const defaultColor = settings.radioButton?.defaultColor ?? "#ADADAD";
  const selectedColor = settings.radioButton?.selectedColor ?? "#EA4758";
  return (
    <svg className={`${className} radio-button-image`} style={{flex: "0 0 17px"}} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <title>{`${title} ${isSelected ? "selected" : "not selected"}`}</title>
        {isSelected ? (
          <path
            fill={selectedColor}
            d="M16.5 8.74023C16.5 13.1585 12.9183 16.7402 8.5 16.7402C4.08171 16.7402 0.5 13.1585 0.5 8.74023C0.5 4.32194 4.08171 0.740234 8.5 0.740234C12.9183 0.740234 16.5 4.32194 16.5 8.74023ZM7.57465 12.9762L13.5101 7.04069C13.7117 6.83914 13.7117 6.51233 13.5101 6.31078L12.7802 5.58088C12.5787 5.3793 12.2519 5.3793 12.0503 5.58088L7.20968 10.4215L4.94971 8.16149C4.74816 7.95994 4.42136 7.95994 4.21977 8.16149L3.48987 8.8914C3.28832 9.09294 3.28832 9.41975 3.48987 9.6213L6.84471 12.9761C7.04629 13.1777 7.37306 13.1777 7.57465 12.9762Z"
          />
        ) : (
          <circle stroke={defaultColor} strokeWidth="2" cx="8" cy="8" r="7" />
        )}
      </g>
    </svg>
  );
}

RadioButtonImage.propTypes = RadioButtonImagePropTypes;
