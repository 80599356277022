import { useContext } from "react";
import { StoreContext } from "./FormProvider";

export const useStoreState = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error("Missing StoreProvider");
  }
  return store.state((state) => state);
};

export const useStoreActions = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error("Missing StoreProvider");
  }
  return store.actions;
};

export const useStoreSelectors = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error("Missing StoreProvider");
  }
  return store.selectors;
};
