import { useEffect } from "react";

import Loader from "./components/Loader";
import ErrorBanner from "./components/ErrorBanner";
import Form from "./components/Form";
import { AppStatus } from "./constants";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "./store";

const devMode = import.meta.env.MODE === "development";

function App({ dataContainer }) {
  const { status, settings } = useStoreState();
  const { loadData } = useStoreActions();
  const theme = settings.theme;

  useEffect(() => {
    if (dataContainer == null) {
      return;
    }
    const observer = new MutationObserver(() => {
      const steps = JSON.parse(dataContainer.innerText);
      return loadData(steps);
    });
    observer.observe(dataContainer, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    });
    if (devMode) {
      fetch("http://localhost:8000/form")
        .then((data) => data.json())
        .then((data) => {
          dataContainer.innerText = JSON.stringify(data);
        });
    } else {
      loadData(JSON.parse(dataContainer.innerText));
    }
    return () => observer.disconnect();
  }, [dataContainer, loadData]);

  const widthClass = devMode ? "max-w-2xl" : "w-full";

  return (
    <div className={`${widthClass} ${ theme ? "xl:max-w-xl 2xl:max-w-2xl" : ''} mx-auto my-auto sm:px-10 md:px-0 text-black`}>
      {status === AppStatus.loading && <Loader />}
      {status === AppStatus.error && <ErrorBanner />}
      {status === AppStatus.ready && <Form />}
    </div>
  );
}
App.propTypes = {
  dataContainer: PropTypes.object,
  staticData: PropTypes.object,
};

export default App;
