import FormStep from "../FormStep";
import LegalConsent from "../LegalConsent";
import { NavigationButton } from "../NavigationButton";
import StepIndicator from "../StepIndicator";
import { useStoreActions, useStoreSelectors, useStoreState } from "../../store";
import { useEffect, useRef } from "react";

export default function DarkModeForm() {
  const { settings } = useStoreState();
  const { selectCurrentStep, selectCanAdvance, selectStepNumber, selectNextButtonText } = useStoreSelectors();
  const { navigateForward } = useStoreActions();

  const currentStep = selectCurrentStep();
  const canAdvance = selectCanAdvance();
  const stepNumber = selectStepNumber();
  const { wrapperClasses } = settings;

  const nextButtonText = selectNextButtonText();

  const showLegalConsent = currentStep.lastStep && settings.showLegalConsentText;

  const questionElementRef = useRef(null);
  useEffect(() => {
    const questionElement = questionElementRef.current;
    if (questionElement == null) {
      return;
    }
    if (currentStep.firstStep) {
      return;
    }
    if (currentStep.answers?.some((answer) => answer.selected)) {
      return;
    }
    questionElement.focus();
  }, [currentStep]);

  return (
    <div className={`${wrapperClasses ?? "bg-black"} px-4 py-8 sm:p-20`}>
      {currentStep && (
        <div className={`font-bold mb-4 text-center text-white text-xl`} ref={questionElementRef}>
          {currentStep.question}
        </div>
      )}
      <div className="mx-auto">
        <StepIndicator total={settings.stepper} current={stepNumber} />
        <FormStep />
        {showLegalConsent && <LegalConsent />}
        <div className="w-full flex justify-between">
          <NavigationButton text={nextButtonText} buttonMode={"transparent-square"} onNavButtonClicked={() => navigateForward()} disabled={!canAdvance} />
        </div>
      </div>
    </div>
  );
}
