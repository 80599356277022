// uncomment the line bellow when in development
// import "./index-dev.css";
import "./index.css";
import { renderInstance } from "./render";

const rootNodes = Array.from(document.querySelectorAll(".rlf-root"));
if (rootNodes.length === 0) {
  const rootNode = document.getElementById("rlf-root");
  if (rootNode == null) {
    console.error("Failed to find root element for react form");
  }
  renderInstance(rootNode);
} else {
  rootNodes.forEach(renderInstance);
}
