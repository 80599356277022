import { createContext, useRef } from "react";

import createFormStore from "./state/FormSlice";
import PropTypes from "prop-types";

export const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = createFormStore();
  }
  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.node,
};
